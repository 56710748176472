function domReady (fn) {
  // If we're early to the party
  document.addEventListener('DOMContentLoaded', fn)
  // If late; I mean on time.
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    fn()
  }
}

domReady(() => {
  const sliderEl = document.querySelector('#landing-slider .slider')

  tns({
    container: sliderEl,
    mode: 'carousel',
    items: 1,
    edgePadding: 0,
    gutter: 0,
    slideBy: 1,
    autoplay: true,
    mouseDrag: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    touch: false,
    autoplayTimeout: 3000,
    speed: 3000,
    preventActionWhenRunning: true
  })
})
